import CustomModal from "../v3components/shared/Modal/CustomModal"
import { reactive, shallowRef } from "vue"

const modalPlugin = (app) => {
  app.component("CustomModal", CustomModal)
  const modalConfig = reactive({
    component: null,
    options: { visible: false, size: "", title: "", props: {}, action: "" },
    open: (component = null, options) => {
      modalConfig.options = { ...options, visible: true }
      modalConfig.component = component
        ? shallowRef(component)
        : modalConfig.component
    },
    close: () => {
      modalConfig.component = null
      modalConfig.options = {
        visible: false
      }
    },
    setAction: (actionName) => {
      modalConfig.options.action = actionName
    }
  })

  app.provide("modal", modalConfig)
}

export default modalPlugin
