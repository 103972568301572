import { createStore } from "vuex"
import axios from "axios"
import layout from "./modules/layout"
import authentication from "./modules/authentication"
import rooms from "./modules/rooms"
import users from "./modules/users"
import schools from "./modules/schools"
import outOfOfficePasses from "./modules/outOfOfficePasses"
import proxyPasses from "./modules/proxyPasses"
import activePass from "./modules/activePass"
import comments from "./modules/comments"
import helpCenter from "./modules/helpCenter"
import polarities from "./modules/polarities"
import passes from "./modules/passes"
import periods from "./modules/periods"
import favorites from "./modules/favorites"
import kiosks from "./modules/kiosks"
import sockets from "./modules/sockets"
import updates from "./modules/updates"
import Echo from "laravel-echo"
import { WebSocketPlugin } from "../WebSocketPlugin"
import modules from "./modules/modules"
import contactTracing from "./modules/contactTracing"
import usersImport from "./modules/usersImport"
import passLimits from "./modules/passLimits"
import passBlocks from "./modules/passBlocks"
import summaryReports from "./modules/summaryReports"
import search from "./modules/search"
import files from "./modules/files"
import dashboardCounters from "@/v3components/Dashboard/store.js"
import dashboardTable from "@/v3components/Datatables/DashboardDataTable/store.js"
import adultAptPass from "../v3components/Datatables/AdultAptPassDataTable/store.js"
import studentAptPass from "../v3components/Datatables/StudentAptPassDataTable/store.js"
import tokenService from "@/helpers/tokenService.js"
import appConfig from "@/helpers/appConfig.js"
import tardyModules from "./modules/Tardy/index"
import visitorModules from './modules/Visitor/index'
import routerDependencies from "./modules/Router/routerDependencies"

//Set Base API URL
axios.defaults.baseURL = appConfig.api + "v5"
axios.defaults.withCredentials = appConfig.axios().withCredentials

let EchoInstance = null
if (process.env.VUE_APP_SOCKET_PROVIDER == "PUSHER") {
  window.Pusher = require("pusher-js")
  EchoInstance = new Echo({
    broadcaster: "pusher",
    authEndpoint: `${process.env.VUE_APP_AUTH_URL}/api/v5/broadcasting/auth`,
    key: process.env.VUE_APP_PUSHER_APP_KEY,
    cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
    auth: {
      headers: {
        Authorization: "Bearer " + tokenService.getToken()
      }
    },
    forceTLS: true
  })
} else {
  window.io = require("socket.io-client")
  EchoInstance = new Echo({
    broadcaster: "socket.io",
    host: process.env.VUE_APP_WS_HOST,
    authEndpoint: `${process.env.VUE_APP_AUTH_URL}/api/v5/broadcasting/auth`,
    encrypted: process.env.VUE_APP_WS_ENCRYPTED === "true",
    auth: {
      headers: {
        Authorization: "Bearer " + tokenService.getToken()
      }
    },
    transports: ["websocket"]
  })
}

const websocket = WebSocketPlugin(EchoInstance)
window.ehpwebsocket = websocket
window.EchoInstance = EchoInstance

const debug = process.env.NODE_ENV !== "production"

const store = createStore({
  modules: {
    layout,
    authentication,
    rooms,
    users,
    schools,
    outOfOfficePasses,
    proxyPasses,
    polarities,
    passes,
    periods,
    activePass,
    comments,
    helpCenter,
    favorites,
    kiosks,
    sockets,
    updates,
    modules,
    contactTracing,
    usersImport,
    passLimits,
    passBlocks,
    summaryReports,
    search,
    files,
    dashboardCounters,
    dashboardTable,
    adultAptPass,
    studentAptPass,
    ...tardyModules,
    ...visitorModules,
    routerDependencies
  },
  mutations: {},
  strict: debug,
  plugins: [websocket]
})

export default store
