import axios from "axios"
import { apiV6nocache, apiV7nocache } from "../../store/modules/axiousWithCache"
import { Promise } from "core-js"

const state = {
  rooms: [],
  editableRoom: null,
  userAssignRooms: null,
  roomsPinData: null,
  autoPassLimits: null,
  roomCapacityLimits: null,
  editableRoomCapacityLimit: null,
  locationRestrictions: null,
  roomsUpdated: false
}

const getters = {
  rooms(state) {
    return state.rooms
  },
  editableRoom(state) {
    return state.editableRoom
  },
  editableRoomCapacityLimit(state) {
    return state.editableRoomCapacityLimit
  },
  formSelectRooms(state) {
    if (state.rooms) {
      return state.rooms.map((room) => {
        return { value: room.id, label: room.name }
      })
    }
  },
  formListRooms(state) {
    if (state.rooms.length) {
      return state.rooms.data.map((room) => {
        return {
          value: {
            id: room.id,
            type: "App\\Models\\Room",
            isFavorite: room.favorites,
            icon: room.icon,
            comment_type: room.comment_type
          },
          label: room.name
        }
      })
    }
  },
  userAssignRooms(state) {
    return state.userAssignRooms
  },
  roomsPinData(state) {
    return state.roomsPinData
  },
  formListUserAssignRooms(state) {
    if (state.userAssignRooms) {
      return state.userAssignRooms.map((room) => {
        return {
          value: {
            id: room.room.id,
            type: "App\\Models\\Room",
            isFavorite: room.room.favorites,
            icon: room.room.icon
          },
          label: room.room.name
        }
      })
    }
  },
  autoPassLimits(state) {
    return state.autoPassLimits
  },
  roomCapacityLimits(state) {
    return state.roomCapacityLimits
  },
  locationRestrictions(state) {
    return state.locationRestrictions
  },
  roomsUpdated(state) {
    return state.roomsUpdated
  }
}

const mutations = {
  SET_ROOMS(state, roomsData) {
    state.rooms = roomsData
  },
  PUSH_ROOMS(state, roomsCollection = []) {
    if (state?.rooms?.data && state.rooms.data.length) {
      state.rooms.data = state.rooms.data.concat(roomsCollection)
    } else {
      state.rooms.data = roomsCollection
    }
  },
  SET_USER_ASSIGN_ROOMS(state, roomsData) {
    state.userAssignRooms = roomsData
  },
  SET_USER_ASSIGN_ROOMS_LISENER(state, roomsData) {
    if (state.userAssignRooms) {
      const newCollection = state.userAssignRooms.map((room) => {
        if (
          (room.room_id && room.room_id == roomsData.roomId) ||
          (room.room && room.room.id == roomsData.roomId)
        ) {
          return {
            ...room,
            room_pin: roomsData.newPin ? roomsData.newPin : null
          }
        }
        return { ...room }
      })
      state.userAssignRooms = newCollection
    }
  },
  SET_USER_ASSIGN_ROOMS_CHANGE_PIN(state, newPinData) {
    state.roomsPinData = newPinData
  },
  SET_AUTO_PASS_LIMITS(state, data) {
    state.autoPassLimits = data
  },
  DELETE_ASSIGN_ROOM(state, roomID) {
    state.userAssignRooms = state.userAssignRooms.filter(
      (el) => el.room_id != roomID
    )
  },
  SET_EDITABLE_ROOM(state, room) {
    state.editableRoom = room
  },
  SET_EDITABLE_ROOM_CAP_LIMIT(state, limit) {
    state.editableRoomCapacityLimit = limit
  },
  SET_ROOM_CAPACITY_LIMITS(state, limits) {
    state.roomCapacityLimits = limits
  },
  PUSH_ROOM_CAPACITY_LIMITS(state, limits) {
    if (state.roomCapacityLimits && state.roomCapacityLimits.length) {
      state.roomCapacityLimits = state.roomCapacityLimits.concat(limits)
    } else {
      state.polarities = limits
    }
  },
  PUSH_ROOM_CAPACITY_LIMIT(state, passLimit) {
    if (state.roomCapacityLimits && state.roomCapacityLimits.length) {
      state.roomCapacityLimits.push(passLimit)
    } else {
      state.roomCapacityLimits = [passLimit]
    }
  },
  UPDATE_ROOM_CAPACITY_LIMIT(state, passLimit) {
    if (state.roomCapacityLimits) {
      const newCollection = state.roomCapacityLimits.map((pass) => {
        if (pass.id === passLimit.id) {
          return passLimit
        }
        return pass
      })
      state.roomCapacityLimits = newCollection
    }
  },
  DELETE_ROOM_CAPACITY_LIMIT(state, limitID) {
    state.roomCapacityLimits = state.roomCapacityLimits.filter(
      (el) => el.id != limitID
    )
  },
  SET_LOCATION_RESTRICTIONS(state, restrictions) {
    state.locationRestrictions = restrictions
  },
  PUSH_LOCATION_RESTRICTIONS(state, restrictions) {
    if (state.locationRestrictions && state.locationRestrictions.length) {
      state.locationRestrictions =
        state.locationRestrictions.concat(restrictions)
    } else {
      state.locationRestrictions = restrictions
    }
  },
  PUSH_LOCATION_RESTRICTION(state, restriction) {
    if (state.locationRestrictions && state.locationRestrictions.length) {
      state.locationRestrictions.push(restriction)
    } else {
      state.locationRestrictions = [restriction]
    }
  },
  DELETE_LOCATION_RESTRICTION(state, id) {
    state.locationRestrictions = state.locationRestrictions.filter(
      (el) => el.id != id
    )
  },
  UPDATE_LOCATION_RESTRICTION(state, restriction) {
    const newCollection = state.locationRestrictions.map((el) => {
      if (el.id === restriction.id) {
        return restriction
      }
      return el
    })
    state.locationRestrictions = newCollection
  },
  SET_ROOMS_UPDATED(state, updated) {
    state.roomsUpdated = updated
  },
  UPDATE_ROOM(state, room) {
    const newCollection = state.rooms.data.map((el) => {
      if (el.id === room.id) {
        return Object.assign(el, room)
      }
      return el
    })
    state.rooms.data = newCollection
  }
}

const actions = {
  getRooms(context, filterData = null) {
    return new Promise((resolve, reject) => {
      axios
        .get("/admin/rooms", {
          params: filterData
        })
        .then((response) => {
          if (!filterData || filterData.commitToTheStore) {
            const data = response.data
            context.commit("SET_ROOMS", data)
          }
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  toggleAPTRequest(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/admin/rooms/${data.id}/toggleAPT`)
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getUserAssignRooms(context) {
    return new Promise((resolve, reject) => {
      axios
        .get("/admin/rooms/assign")
        .then((response) => {
          const data = response.data.data
          if (data && data.assigned_rooms) {
            context.commit("SET_USER_ASSIGN_ROOMS", data.assigned_rooms)
          }
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  assignRoomsToUser(context, rooms) {
    return new Promise((resolve, reject) => {
      axios
        .post("/admin/rooms/assign", rooms)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  deleteAssingRooms(context, room_id) {
    return new Promise((resolve, reject) => {
      axios
        .delete("/admin/rooms/assign", {
          params: {
            room_ids: [room_id]
          }
        })
        .then((response) => {
          context.commit("DELETE_ASSIGN_ROOM", room_id)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  createRoom(context, room) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/admin/rooms`, room)
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  updateRoom(context, room) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/admin/rooms/${room.roomId}`, room.data)
        .then((response) => {
          context.commit("SET_ROOMS_UPDATED", true)
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  updateRoomIcon(_, room) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/admin/rooms/${room.roomId}/icon`, room.data)
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  importRooms(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/admin/rooms/import/csv`, data)
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  createRoomRestriction(_, roomRestriction) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/admin/location-restrictions`, roomRestriction)
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getLocationRestrictions(_, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/admin/location-restrictions`, {
          params
        })
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getCSVroomCapacityLimitsExport(_, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/admin/capacity-limits/export/csv`, {
          params: params
        })
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getCSVlocationRestrictionsExport(_, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/admin/location-restrictions/export/csv`, {
          params: params
        })
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  deleteAllRestrictions() {
    return new Promise((resolve, reject) => {
      apiV6nocache
        .delete(`/admin/location-restrictions`)
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  deleteLocationRestriction(context, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/admin/location-restrictions/${id}`)
        .then((response) => {
          context.commit("DELETE_LOCATION_RESTRICTION", id)
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  deleteLocationRestrictions(_, restrictionsIds) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/admin/location-restrictions/delete/bulk`, {
          room_restriction_ids: restrictionsIds
        })
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  toggleLocationRestriction(_, id) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/admin/location-restrictions/${id}/status`)
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getRoomCapacityLimit(_, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/admin/capacity-limits`, {
          params
        })
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  createRoomCapacityLimit(_, locationLimit) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/admin/capacity-limits`, locationLimit)
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  deleteRoomCapacityLimit(_, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/admin/capacity-limits/${id}`)
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  updateRoomCapacityLimit(_, locationLimit) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/admin/capacity-limits/${locationLimit.id}`, {
          limit: locationLimit.limit
        })
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  addAutoPassLimit(_, limitData) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/admin/rooms/autopass/preference`, limitData)
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getAutoPassLimitV7(context, paginationData) {
    return new Promise((resolve, reject) => {
      apiV7nocache
        .get(`/admin/rooms/autopass`, {
          params: paginationData
        })
        .then((response) => {
          const data = response.data
          context.commit("SET_AUTO_PASS_LIMITS", data)
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getAutoPassLimit(context) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/admin/rooms/autopass`)
        .then((response) => {
          const data = response.data.data
          context.commit("SET_AUTO_PASS_LIMITS", data)
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getCapacityReached(context, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/admin/capacity-limits/reached/${id}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getUnavailability(context, value) {
    return new Promise((resolve, reject) => {
      axios
        .get(`unavailables/check/${value.id}`, {
          params: {
            apt_for_time: value.apt_for_time,
            type: value.type
          }
        })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  setAutoPassLimitV7(context, data) {
    return new Promise((resolve, reject) => {
      apiV7nocache
        .post(`/admin/rooms/autopass`, data)
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  setAutoPassLimit(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/admin/rooms/autopass`, data)
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  deleteAutoPassLimit(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/admin/rooms/autopass`, {
          params: {
            autopass_rooms: [data]
          }
        })
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  exportRooms(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/admin/rooms/export/csv`, { params: data })
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  exportExampleCSV() {
    return new Promise((resolve, reject) => {
      axios
        .get(`/admin/rooms/export/csv_template`)
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
