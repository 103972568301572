import axios from "axios"
import { Promise } from "core-js"
import { apiV7nocache } from "./axiousWithCache"
import helpers from "@/helpers/index"
import moment from "moment-timezone"

const state = {
  users: null,
  favorites: null,
  editableUser: null,
  editableSubstitute: null,
  stats: null,
  synchronization: null,
  studentIdCardsLink: null
}

const getters = {
  users(state) {
    return state.users
  },
  students(state) {
    return state.users ? state.users.filter((user) => user.role_id === 1) : null
  },
  staffUsers(state) {
    return state.users ? state.users.filter((user) => user.role_id != 1) : null
  },
  substituteUsers(state) {
    return state.users && state.users.length
      ? state.users.filter((user) => user.is_substitute)
      : null
  },
  archivedUsers(state) {
    return state.users
      ? state.users.filter(
          (user) =>
            user.current_role_in_school &&
            user.current_role_in_school.pivot.archived_at != null
        )
      : null
  },
  formListStudents(state, getters) {
    if (getters.students) {
      return getters.students.map((student) => {
        return {
          value: {
            id: student.id,
            type: "App\\Models\\User",
            passesToday: student.passes_for_today_count
              ? parseInt(student.passes_for_today_count)
              : 0
          },
          label: student.first_name + " " + student.last_name
        }
      })
    }
  },
  formListStaff(state, getters) {
    if (getters.staffUsers) {
      return getters.staffUsers.map((user) => {
        return {
          value: {
            id: user.id,
            type: "App\\Models\\User",
            avatar: user.avatar,
            comment_type: user.comment_type
          },
          label: user.first_name + " " + user.last_name
        }
      })
    }
  },
  multiSelectStudentsList(state, getters) {
    if (getters.students) {
      return getters.students.map((student) => {
        return {
          value: student.id,
          label:
            student.first_name + " " + student.last_name + ", " + student.email
        }
      })
    }
  },
  fromFavorites(state) {
    return state.favorites
  },
  editableUser(state) {
    return state.editableUser
  },
  editableSubstitute(state) {
    return state.editableSubstitute
  },
  stats(state) {
    return state.stats
  },
  synchronization(state) {
    return state.synchronization
  },
  studentIdCardsLink(state) {
    return state.studentIdCardsLink
  }
}

const mutations = {
  SET_USERS(state, usersCollection) {
    state.users = usersCollection
  },
  PUSH_USERS(state, usersCollection) {
    if (state.users && state.users.length) {
      state.users = state.users.concat(usersCollection)
    } else {
      state.users = usersCollection
    }
  },
  PUSH_USER(state, user) {
    if (state.users != null && state.users.length) {
      state.users.push(user)
    } else {
      state.users = [user]
    }
  },
  REMOVE_USER(state, user) {
    state.users = state.users.filter((student) => {
      return student.id !== user.id
    })
  },
  SET_FAVORITES(state, favoritesCollection) {
    state.favorites = favoritesCollection
  },
  SET_EDITABLE_USER(state, user) {
    state.editableUser = user
  },
  SET_EDITABLE_SUBSTITUTE(state, user) {
    state.editableSubstitute = user
  },
  SET_STATS(state, statsData) {
    state.stats = statsData
  },
  SET_SYNCHRONIZATION(state, syncInfo) {
    state.synchronization = syncInfo
  },
  UPDATE_SUB_USER(state, userObj) {
    const newCollection = state.users.map((user) => {
      if (user.id === userObj.id) {
        return userObj
      }
      return user
    })
    state.users = newCollection
  },
  SET_STUDENT_CARDS_LINK(state, url) {
    state.studentIdCardsLink = url
  }
}

const actions = {
  updateKioskPass(context, user) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/users/${user.id}/edit`, user.passcode)
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getUsers(context, filterData) {
    return new Promise((resolve, reject) => {
      apiV7nocache
        .get("/admin/users", {
          params: filterData
        })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getSubstituteUsers(context, filterData) {
    return new Promise((resolve, reject) => {
      axios
        .get("/admin/users/teachers/substitutes", {
          params: filterData
        })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  createUser(context, user) {
    return new Promise((resolve, reject) => {
      apiV7nocache
        .post("/admin/users", user)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateUser(context, user) {
    return new Promise((resolve, reject) => {
      apiV7nocache
        .put(`/admin/users/${user.userId}/edit`, user.data)
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  createSubstituteTeacher(context, user) {
    return new Promise((resolve, reject) => {
      axios
        .post("/admin/users/teachers/create?is_substitute=true", user)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateSubstituteTeacher(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(
          `/admin/users/teachers/substitute/${data.userId}?is_substitute=true`,
          data.data
        )
        .then((response) => {
          const userObj = response.data.data
          if (userObj) {
            context.commit("UPDATE_SUB_USER", userObj)
          }
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  archiveSingle(context, userId) {
    return new Promise((resolve, reject) => {
      apiV7nocache
        .put("/admin/users/archives/" + userId + "/archive")
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  unArchive(context, user) {
    return new Promise((resolve, reject) => {
      apiV7nocache
        .put("/admin/users/archives/" + user.id + "/unarchive")
        .then((response) => {
          context.commit("REMOVE_USER", user)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  substituteDeleteBulk(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/admin/users/teachers/substitute/delete/bulk", data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  substituteBulkPin(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/admin/users/pin/create", data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  deactivateSubstituteTeacher(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/admin/users/teachers/substitute/${data.userId}/delete`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  activateSubstituteTeacher(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/admin/users/teachers/substitute/${data.userId}/activate`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  inviteBulk(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/admin/users/invites/bulk`, data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  archiveBulk(context, data) {
    return new Promise((resolve, reject) => {
      apiV7nocache
        .put("/admin/users/archives/bulk/archive", data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  unArchiveBulk(context, data) {
    return new Promise((resolve, reject) => {
      apiV7nocache
        .put("/admin/users/archives/bulk/unarchive", data)
        .then((response) => {
          context.dispatch("getUsers")
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  exportUsers(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .get("/admin/users/export/csv", {
          params: {
            ...data,
            substitute: data.is_substitute ? data.is_substitute : false,
            archived: data.is_archived ? data.is_archived : false,
            all_entries: data.all_entries
          }
        })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getStats(context) {
    return new Promise((resolve, reject) => {
      axios
        .get("/admin/users/statistics")
        .then((response) => {
          const data = response.data.data.stats
          if (data) {
            context.commit("SET_STATS", data)
          }
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  recreateUser(context, user) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/admin/users/${user.id}/recreate`)
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getSynchronization(context) {
    return new Promise((resolve, reject) => {
      axios
        .get("/admin/synchronization")
        .then((response) => {
          const data = response.data.data
          if (data) {
            context.commit("SET_SYNCHRONIZATION", data)
          }
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  synchronization(context, id) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/admin/synchronization/${id}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getAllStudentIDCardsPDF(context) {
    return new Promise((resolve, reject) => {
      apiV7nocache
        .get("/admin/users/pdf")
        .then((response) => {
          const data = response.data.data
          if (data) {
            const isFileStillValid =
              !!data.expired_at &&
              moment(helpers.transformUTC(data.expired_at)).isAfter(
                moment(helpers.getToday())
              )
            context.commit(
              "SET_STUDENT_CARDS_LINK",
              isFileStillValid ? data.url : null
            )
          }
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  generateAllStudentIDCardsFile() {
    return new Promise((resolve, reject) => {
      apiV7nocache
        .post("/admin/users/pdf")
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  generateSpecificStudentIDCard(context, studentId) {
    return new Promise((resolve, reject) => {
      apiV7nocache
        .post(`/users/pdf/${studentId}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
